import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '', 
});

// Añadir un interceptor para incluir el token en las cabeceras
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Manejar respuestas de error globalmente
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Redirigir al usuario al login si el token es inválido o ha expirado
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
