import React from 'react';

function Alumno() {
    return (
        <div>
            <h1>Examen para Alumnos</h1>
            <p>Contenido específico para alumnos...</p>
        </div>
    );
}

export default Alumno;
