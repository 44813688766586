// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from '../axiosConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyToken = async () => {
            const storedToken = localStorage.getItem('token');
            console.log('Stored Token:', storedToken);
            if (storedToken) {
                try {
                    const response = await axios.get('/verify-token', { 
                        headers: { 'Authorization': `Bearer ${storedToken}` }
                    });
                    console.log('Verify Token Response:', response.data);
                    if (response.status === 200) {
                        setToken(storedToken);
                    } else {
                        setToken(null);
                        localStorage.removeItem('token');
                    }
                } catch (error) {
                    console.error('Error verifying token:', error);
                    setToken(null);
                    localStorage.removeItem('token');
                }
            }
            setLoading(false);
        };

        verifyToken();
    }, []);

    const login = (newToken) => {
        localStorage.setItem('token', newToken);
        setToken(newToken);
    };

    const logout = () => {
        localStorage.removeItem('token');
        setToken(null);
    };

    return (
        <AuthContext.Provider value={{ token, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
