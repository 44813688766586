import React from 'react';

function Institucion() {
    return (
        <div>
            <h1>Institución</h1>
            <p>Contenido específico para Institución...</p>
        </div>
    );
}

export default Institucion;
