// App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Docente from './components/Docente';
import { AuthContext } from './context/AuthContext';
import Alumno from './components/Alumno'; 
import Institucion from './components/Institucion';

function App() {
    const { token, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/login" element={!token ? <Login /> : <Navigate to="/" />} />
                <Route 
                    path="/examen_docente" 
                    element={token ? <Docente /> : <Navigate to="/login" />} 
                />
                <Route 
                    path="/examen_alumno" 
                    element={token ? <Alumno /> : <Navigate to="/login" />} 
                />
                <Route 
                    path="/index_institucion" 
                    element={token ? <Institucion /> : <Navigate to="/login" />} 
                />
                {/* Ruta por defecto */}
                <Route 
                    path="/" 
                    element={<Navigate to={token ? '/examen_docente' : '/login'} />} 
                />
            </Routes>
        </Router>
    );
}

export default App;