import React, { useContext } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../context/AuthContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6', 
    },
    background: {
      default: '#f4f6f8' 
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)' 
        }
      }
    }
  }
});

function Login() {
  const { login } = useContext(AuthContext);
  const [error, setError] = React.useState('');

  const formik = useFormik({
    initialValues: {
      usuario: '',
      password: '',
    },
    validationSchema: Yup.object({
      usuario: Yup.string()
        .max(50, 'Máximo 50 caracteres')
        .required('Requerido'),
      password: Yup.string()
        .max(50, 'Máximo 50 caracteres')
        .required('Requerido'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setError('');
      try {
        const { data } = await axios.post('/api/login', values);
        login(data.token);
        window.location.href = data.path;
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setError('Usuario o contraseña incorrecta.');
          } else if (error.response.status === 429) {
            setError('Demasiados intentos de login. Por favor, intente más tarde.');
          } else {
            setError('Error en el servidor. Por favor, inténtelo más tarde.');
          }
        } else {
          setError('Error de red. Por favor, verifique su conexión.');
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{ bgcolor: 'background.default' }}
      >
        <Card sx={{ minWidth: 275, maxWidth: 400 }}>
          <CardContent>
            <Typography variant="h5" component="h2" marginBottom={2} textAlign="center">
              Iniciar Sesión
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                label="Usuario"
                fullWidth
                required
                margin="normal"
                variant="outlined"
                color="primary"
                id="usuario"
                name="usuario"
                value={formik.values.usuario}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.usuario && Boolean(formik.errors.usuario)}
                helperText={formik.touched.usuario && formik.errors.usuario}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                label="Contraseña"
                type="password"
                fullWidth
                required
                margin="normal"
                variant="outlined"
                color="primary"
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                inputProps={{ maxLength: 50 }}
              />
              <Button 
                type="submit" 
                fullWidth 
                variant="contained" 
                color="primary" 
                sx={{ mt: 2, py: 1 }}
                disabled={formik.isSubmitting}
              >
                Entrar
              </Button>
            </form>
            {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
