// Docente.js
import React, { useEffect, useState, useContext } from 'react';
import axios from '../axiosConfig'; // Usamos la instancia configurada de Axios
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, Button } from '@mui/material';
import { AuthContext } from '../context/AuthContext';

function Docente() {
    const { logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [hasRecord, setHasRecord] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/examen_docente');
                if (response.data.hasRecord) {
                    setHasRecord(true);
                    setData(response.data.data);
                } else {
                    setHasRecord(false);
                }
            } catch (err) {
                console.error('Error al obtener los datos:', err);
                setError('Error al cargar los datos.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" flexDirection="column">
                <Typography color="error">{error}</Typography>
                <Button variant="contained" color="primary" onClick={logout} sx={{ mt: 2 }}>
                    Volver al Login
                </Button>
            </Box>
        );
    }

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                Examen para Docentes
            </Typography>
            {hasRecord ? (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Contenido de Prueba
                    </Typography>
                    {/* Aquí puedes agregar el contenido específico para docentes */}
                    <p>Contenido específico para docentes...</p>
                    {/* Por ejemplo, podrías mostrar los datos obtenidos */}
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* Define las columnas según tu estructura */}
                                    <TableCell>ID Examen</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Estatus</TableCell>
                                    {/* Agrega más columnas si es necesario */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((exam) => (
                                    <TableRow key={exam.id}>
                                        <TableCell>{exam.id}</TableCell>
                                        <TableCell>{new Date(exam.date).toLocaleString('es-MX', { timeZone: 'America/Chihuahua' })}</TableCell>
                                        <TableCell>{exam.estatus === 0 ? 'Pendiente' : 'Completado'}</TableCell>
                                        {/* Agrega más celdas si es necesario */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Resultados</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>No hay resultados disponibles.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
}

export default Docente;
